// src/useGroupMembership.js
import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';

const useGroupMembership = () => {
  const { accounts } = useMsal();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (accounts.length > 0) {
      const idToken = accounts[0].idTokenClaims;

      // GroupObjectID that is managed in Entra 'GenAIPromptLibraryGroupAdmins'
      const adminGroupId = 'ac9fd922-6a40-4c26-9b9f-0bf295d6c5bf';
      const groups = idToken.groups || [];

      if (groups.includes(adminGroupId)) {
        setIsAdmin(true);
      }
    }
  }, [accounts]);

  return isAdmin;
};

export default useGroupMembership;
