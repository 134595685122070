// src/useProfilePicture.js
import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

const useProfilePicture = () => {
  const { instance, accounts } = useMsal();
  const [profilePicture, setProfilePicture] = useState(null);

  useEffect(() => {
    if (accounts.length > 0) {
      const request = {
        scopes: ["User.Read", "User.Read.All"],
        account: accounts[0],
      };

      instance.acquireTokenSilent(request).then(response => {
        fetch('https://graph.microsoft.com/v1.0/me/photo/$value', {
          headers: {
            Authorization: `Bearer ${response.accessToken}`,
          },
        })
          .then(res => {
            if (res.ok) {
              return res.blob();
            }
            throw new Error('Failed to fetch profile picture');
          })
          .then(blob => {
            const url = URL.createObjectURL(blob);
            setProfilePicture(url);
          })
          .catch(error => {
            if (error instanceof InteractionRequiredAuthError) {
              instance.acquireTokenPopup(request).then(response => {
                fetch('https://graph.microsoft.com/v1.0/me/photo/$value', {
                  headers: {
                    Authorization: `Bearer ${response.accessToken}`,
                  },
                })
                  .then(res => {
                    if (res.ok) {
                      return res.blob();
                    }
                    throw new Error('Failed to fetch profile picture');
                  })
                  .then(blob => {
                    const url = URL.createObjectURL(blob);
                    setProfilePicture(url);
                  });
              });
            }
          });
      });
    }
  }, [accounts, instance]);

  return profilePicture;
};

export default useProfilePicture;
