// src/ProtectedRoute.js
import React from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ element }) => {
  const isAuthenticated = useIsAuthenticated();

  return isAuthenticated ? element : <Navigate to="/login" />;
};

export default ProtectedRoute;
