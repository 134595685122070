// src/Login.js
import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from './authConfig';
import { useNavigate } from 'react-router-dom';
import './Login.css';

function Login() {
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch(e => {
      console.error(e);
    });
  };

  useEffect(() => {
    if (accounts.length > 0) {
      navigate('/app'); // Navigate to the main content when authenticated
    }
  }, [accounts, navigate]);

  return (
    <div className="login-page">
      <div className="login-container">
        <h1>Welcome to the Slalom Prompt Library</h1>
        <button onClick={handleLogin} className="auth-button">Login</button>
      </div>
    </div>
  );
}

export default Login;
