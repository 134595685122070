import React, { useEffect, useRef, useState } from 'react';
import './HowToWritePrompts.css';

const HowToWritePrompts = () => {
  const sectionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (sectionRef.current) {
        const rect = sectionRef.current.getBoundingClientRect();
        if (rect.top <= window.innerHeight * 0.75) {
          setIsVisible(true);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check in case the section is already in view

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      ref={sectionRef}
      className={`how-to-write-prompts ${isVisible ? 'visible' : ''}`}
    >
      <h2>How To Write An Effective Prompt</h2>
      <p className="intro-text">
        To get the best results from your AI tools, you need to write clear prompts with context, details, and guidelines for the AI.
      </p>
      <div className="prompt-tips">
        <div className="prompt-tip">
          <h3>Specify the Output Format You Want</h3>
          <p>
            When creating a prompt, you should include specific details that shape the output you desire. This could involve defining the length, format, structure, tone, and other relevant aspects. The more precise you are, the more likely the AI will meet your expectations.
          </p>
        </div>
        <div className="prompt-tip">
          <h3>Include Examples in Your Prompt</h3>
          <p>
            Providing examples within your prompt can significantly enhance the AI’s performance. By including specific samples of the type of output you want, you give the AI a clearer direction, leading to better and more accurate results.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowToWritePrompts;
