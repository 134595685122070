import React from 'react';
import './Sidebar.css';
import { NavLink } from 'react-router-dom';

const Sidebar = ({ isOpen, toggleMenu }) => {
  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <div className="menu-container">
        <NavLink to="/" className="sidebar-button">Home</NavLink>
        <NavLink to="/templates" className="sidebar-button">Templates</NavLink>
        <button className="sidebar-button" onClick={() => window.location.href = 'https://business.slalom.com/chat-ai'}>Slalom Chat</button>
        <button className="sidebar-button gray">Finance Chat</button>
      </div>
    </div>
  );
};

export default Sidebar;
