import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react'; // MSAL library for authentication
import './Home.css'; // Importing the CSS specific to this component
import HowToWritePrompts from './HowToWritePrompts'; // Component for "How to Write Prompts" section
import FAQ from './FAQ'; // FAQ component
import useGroupMembership from './useGroupMembership'; // Custom hook to check if the user is an admin
import Modal from './Modal'; // Reusable modal component
import Spinner from './Spinner'; // Loading spinner

// Constants for prompt categories and their colors
const CATEGORIES = [
  { name: "BI", color: "#FF0000" }, // Business Intelligence category
  { name: "IT", color: "#800080" }, // IT category
  { name: "HR", color: "#008000" } // Human Resources category
];

// Character limits for title and description previews
const TITLE_MAX_LENGTH = 40;
const DESCRIPTION_PREVIEW_LENGTH = 50;

function Home() {
  const { accounts } = useMsal(); // Using MSAL to get account details
  const [isAuthenticated, setIsAuthenticated] = useState(false); // State to track if user is authenticated
  const [prompts, setPrompts] = useState([]); // State to store prompts fetched from API
  const [isLoading, setIsLoading] = useState(true); // State to show loading spinner while data is fetched
  const [searchTerm, setSearchTerm] = useState(''); // State to track search input
  const [selectedCategory, setSelectedCategory] = useState('All'); // State for category filter
  const [viewingPrompt, setViewingPrompt] = useState(null); // State for viewing a specific prompt
  const [isFormOpen, setIsFormOpen] = useState(false); // State to track if form modal is open
  const [formType, setFormType] = useState(''); // State to track if form is for create or edit
  const [formPrompt, setFormPrompt] = useState({ id: '', title: '', description: '', category: '' }); // State to hold form data
  const [formTitle, setFormTitle] = useState(''); // State for form modal title
  const [copyButtonText, setCopyButtonText] = useState('Copy Prompt'); // State for copy button text
  const isAdmin = useGroupMembership(); // Check if the user is an admin
  const API_KEY = process.env.REACT_APP_API_KEY; // API key from environment variables

  // Check if the user is authenticated based on MSAL accounts
  useEffect(() => {
    if (accounts.length > 0) {
      setIsAuthenticated(true);
    }
  }, [accounts]);

  // Fetch prompts from the API on component load
  useEffect(() => {
    fetch('https://a5p15lnf7j.execute-api.us-west-2.amazonaws.com/dev/prompts', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': API_KEY,
      },
    })
      .then(res => res.json())
      .then(data => {
        const unwrappedData = data.map(item => ({
          id: item.id.S,
          title: item.title.S,
          description: item.description.S,
          category: item.category.S
        }));
        setPrompts(unwrappedData);
        setIsLoading(false); // Stop loading once data is fetched
      })
      .catch(err => {
        console.error('Error fetching prompts:', err);
        setIsLoading(false); // Stop loading in case of an error
      });
  }, [API_KEY]);

  // Handle viewing a specific prompt
  const handleViewPrompt = (prompt) => {
    setViewingPrompt(prompt);
    setTimeout(() => {
      const promptContainer = document.querySelector('.view-prompt-container');
      if (promptContainer) {
        promptContainer.classList.add('active'); // Add animation when viewing a prompt
      }
    }, 10);
  };

  // Handle going back to prompt list from viewing a prompt
  const handleBackToPrompts = () => {
    setViewingPrompt(null);
  };

  // Handle copying prompt description to clipboard
  const handleCopyPrompt = (description) => {
    navigator.clipboard.writeText(description).then(() => {
      setCopyButtonText('Copied'); // Change button text to "Copied" temporarily
      setTimeout(() => setCopyButtonText('Copy Prompt'), 2000); // Reset text after 2 seconds
    }, () => {
      setCopyButtonText('Copy Prompt');
    });
  };

  // Open form for either creating or editing a prompt
  const openForm = (type, prompt = { id: '', title: '', description: '', category: '' }) => {
    setFormType(type);
    setFormTitle(type === 'submit' ? 'Create Prompt' : 'Edit Prompt');
    setFormPrompt({ ...prompt });
    setIsFormOpen(true); // Open the modal form
  };

  // Close the modal form
  const closeForm = () => {
    setIsFormOpen(false);
  };

  // Handle changes in the form inputs
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "title" && value.length > TITLE_MAX_LENGTH) return; // Restrict title length
    setFormPrompt(prevState => ({ ...prevState, [name]: value })); // Update the form state
  };

  // Handle form submission for creating or editing a prompt
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formPrompt.id && formType === 'edit') {
      console.error('Invalid formPrompt.id:', formPrompt.id);
      return;
    }

    const endpoint = formType === 'submit'
      ? 'https://a5p15lnf7j.execute-api.us-west-2.amazonaws.com/dev/prompts' // API endpoint for creating prompt
      : `https://a5p15lnf7j.execute-api.us-west-2.amazonaws.com/dev/prompts/${formPrompt.id}`; // API endpoint for editing

    const method = formType === 'submit' ? 'POST' : 'PUT'; // Use POST for create and PUT for edit
    const body = JSON.stringify({
      id: formPrompt.id,
      title: formPrompt.title,
      description: formPrompt.description,
      category: formPrompt.category
    });

    fetch(endpoint, {
      method,
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': API_KEY,
      },
      body
    })
      .then(res => res.json())
      .then(data => {
        if (formType === 'submit') {
          setPrompts(prevPrompts => [...prevPrompts, { ...data, id: data.id.S }]); // Add new prompt to list
        } else {
          setPrompts(prevPrompts => prevPrompts.map(prompt => prompt.id === data.id ? data : prompt)); // Update existing prompt
        }
        setIsFormOpen(false); // Close the form modal
        setFormPrompt({ id: '', title: '', description: '', category: '' }); // Reset form state
      })
      .catch(err => console.error('Error submitting prompt:', err));
  };

  // Handle deleting a prompt
  const handleDelete = (id) => {
    fetch(`https://a5p15lnf7j.execute-api.us-west-2.amazonaws.com/dev/prompts/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': API_KEY,
      },
    })
      .then(res => {
        if (res.ok) {
          setPrompts(prompts.filter(prompt => prompt.id !== id)); // Remove deleted prompt from list
        } else {
          console.error('Error deleting prompt');
        }
      })
      .catch(err => console.error('Error deleting prompt:', err));
  };

  // Handle category filter change
  const handleCategoryChange = (category) => {
    setSelectedCategory(category);

    const promptsContainer = document.querySelector('.popular-prompts');
    if (promptsContainer) {
      promptsContainer.classList.remove('active');

      setTimeout(() => {
        promptsContainer.classList.add('active'); // Animate prompt list when category changes
      }, 300);
    }
  };

  // Filter prompts based on search term and selected category
  const filteredPrompts = prompts.filter(prompt => {
    const searchTermLower = searchTerm.toLowerCase();
    const promptTitle = typeof prompt.title === 'string' ? prompt.title.toLowerCase() : '';
    const matchesSearchTerm = promptTitle.includes(searchTermLower) || 
                              prompt.category.toLowerCase().includes(searchTermLower) || 
                              prompt.id.toLowerCase().includes(searchTermLower) ||
                              prompt.description.toLowerCase().includes(searchTermLower);
    const matchesCategory = selectedCategory === "All" || prompt.category === selectedCategory;
    return (!searchTerm || matchesSearchTerm) && matchesCategory;
  });

  // Utility function to truncate long text
  const truncateText = (text, length) => {
    if (typeof text !== 'string') {
      return '';
    }
    return text.length <= length ? text : `${text.substring(0, length)}...`;
  };

  return (
    <div className="home">
      {isLoading ? (
        <Spinner /> // Show loading spinner while fetching prompts
      ) : !viewingPrompt ? ( // If not viewing a prompt, show prompt list
        <>
          <div className="intro-container">
            <div className="intro-box">
              <h1>Welcome to the Slalom Prompt Library</h1>
              <p>
                The Slalom Prompt Library is a centralized hub designed to enhance your productivity and creativity with AI-powered prompts. Whether you're working in BI, IT, or HR, you’ll find prompts that cater to your specific needs, helping you to streamline tasks, generate new ideas, and collaborate more effectively.
              </p>
            </div>
          </div>

          {/* Category filter buttons */}
          <div className="category-filters">
            <button
              className={`category-filter ${selectedCategory === 'All' ? 'active' : ''}`}
              onClick={() => handleCategoryChange('All')}
            >
              Show All
            </button>
            {CATEGORIES.map(cat => (
              <button
                key={cat.name}
                className={`category-filter ${selectedCategory === cat.name ? 'active' : ''}`}
                onClick={() => handleCategoryChange(cat.name)}
              >
                {cat.name}
              </button>
            ))}
          </div>

          {/* Search bar */}
          <div className="search-section">
            <div className="search-bar">
              <img src="/search.svg" alt="Search" className="search-icon" />
              <input type="text" placeholder="Search" onChange={event => setSearchTerm(event.target.value)} />
            </div>
          </div>

          {/* Display the list of prompts */}
          <section className="ai-prompt-library">
            <div className="popular-prompts active">
              {filteredPrompts.map((prompt) => {
                const categoryColor = CATEGORIES.find(cat => cat.name === prompt.category)?.color || '#ffffff';
                return (
                  <div className="prompt" key={prompt.id}>
                    <div className="prompt-category" style={{ backgroundColor: categoryColor }}>{prompt.category}</div>
                    <h3>{truncateText(prompt.title, TITLE_MAX_LENGTH)}</h3>
                    <p className="prompt-description">{truncateText(prompt.description, DESCRIPTION_PREVIEW_LENGTH)}</p>
                    <div className="prompt-buttons">
                      <button onClick={() => handleViewPrompt(prompt)} className="view-prompt-button">View Prompt</button>
                      {isAdmin && (
                        <>
                          <button onClick={() => openForm('edit', prompt)} className="edit-button">Edit</button>
                          <button onClick={() => handleDelete(prompt.id)} className="delete-button">Delete</button>
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            {isAdmin && (
              <button className="submit-prompt-button" onClick={() => openForm('submit')}>+ Create Prompt</button>
            )}
          </section>
          <HowToWritePrompts />
          <FAQ />
        </>
      ) : ( // If viewing a specific prompt, show the prompt details
        <div className="view-prompt-container">
          <h1>{viewingPrompt.title}</h1>
          <div className="prompt-category" style={{ backgroundColor: CATEGORIES.find(cat => cat.name === viewingPrompt.category)?.color || '#ffffff' }}>{viewingPrompt.category}</div>
          <p className="view-prompt-description">{viewingPrompt.description}</p>
          <p className="view-prompt-id">Prompt ID: {viewingPrompt.id}</p>
          <div className="view-prompt-buttons">
            <button onClick={() => handleCopyPrompt(viewingPrompt.description)} className="view-prompt-copy-button">{copyButtonText}</button>
            <button onClick={handleBackToPrompts} className="view-prompt-back-button">Back to Prompts</button>
          </div>
        </div>
      )}
      {isFormOpen && (
        <Modal isOpen={isFormOpen} onClose={closeForm}>
          <h2>{formTitle}</h2>
          <form onSubmit={handleSubmit} className="prompt-form">
            <label>
              Title:
              <input type="text" name="title" value={formPrompt.title} onChange={handleInputChange} maxLength={TITLE_MAX_LENGTH} required />
            </label>
            <label>
              Description:
              <textarea name="description" value={formPrompt.description} onChange={handleInputChange} required />
            </label>
            <label>
              Category:
              <select name="category" value={formPrompt.category} onChange={handleInputChange} required>
                <option value="">Select Category</option>
                {CATEGORIES.map(category => (
                  <option key={category.name} value={category.name}>{category.name}</option>
                ))}
              </select>
            </label>
            <button type="submit" className="form-submit-button">{formType === 'submit' ? 'Create Prompt' : 'Update Prompt'}</button>
          </form>
        </Modal>
      )}
    </div>
  );
}

export default Home;
