// src/FAQ.js
import React, { useState } from 'react';
import './FAQ.css';

const faqData = [
  {
    question: 'What is a Prompt Library?',
    answer: 'A Prompt Library is a curated collection of prompts, which are statements or questions designed to inspire users and stimulate creativity or thought.'
  },
  {
    question: 'How do I use the Prompt Library?',
    answer: 'It\'s simple. Browse through the categories, select a prompt that interests you, copy the prompt, and start creating! Use the prompts as a starting point for your work.'
  },
  {
    question: 'How Frequently is the Prompt Library updated?',
    answer: 'Our team adds new prompts to the library on a regular basis. We aim to keep the content fresh and inspiring for our users.'
  }
];

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-section">
      <h2>Frequently Asked Questions</h2>
      <div className="faq-container">
        {faqData.map((item, index) => (
          <div key={index} className={`faq-item ${activeIndex === index ? 'active' : ''}`}>
            <div className="faq-question" onClick={() => toggleFAQ(index)}>
              <h3>{item.question}</h3>
              <span className="faq-icon">{activeIndex === index ? '-' : '+'}</span>
            </div>
            <div className="faq-answer">
              <p>{item.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
