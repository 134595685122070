// src/Header.js
import React from 'react';
import useProfilePicture from './useProfilePicture';
import './Header.css';

function Header({ isAuthenticated, username, handleLogout }) {
  const profilePicture = useProfilePicture();

  return (
    <header className="header">
      <div className="header-left">
        <div className="logo-section">
          <img src="/slalom-logo-white-500x140.png" alt="Slalom Logo" className="logo" />
          <span className="library-title">AI Prompt Library</span>
        </div>
      </div>
      <div className="header-right">
        <div className="greeting">
          {isAuthenticated && <span>Hello, {username}</span>}
        </div>
        <div className="profile-section">
          {profilePicture && <img src={profilePicture} alt="Profile" className="profile-picture" />}
          <button className="logout-button" onClick={handleLogout}>
            Logout
          </button>
        </div>
      </div>
    </header>
  );
}

export default Header;
