import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: "f08941f9-5c06-4c51-a1db-efa471ea0ea1", 
    authority: "https://login.microsoftonline.com/9ca75128-a244-4596-877b-f24828e476e2",
    redirectUri: window.location.origin, 
    postLogoutRedirectUri: window.location.origin, 
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage", 
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
    allowNativeBroker: false,
  },
};

export const loginRequest = {
  scopes: ["openid", "profile", "User.Read"],
};

export const silentRequest = {
  scopes: ["openid", "profile"],
  loginHint: "example@domain.net",
};
