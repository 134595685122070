import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react'; // MSAL for authentication
import Header from './Header'; // Reusable header component
import Sidebar from './Sidebar'; // Reusable sidebar component
import Modal from './Modal'; // Reusable modal component
import './Templates.css'; // Importing the CSS specific to this component
import FAQ from './FAQ'; // FAQ component
import HowToWritePrompts from './HowToWritePrompts'; // Component for "How to Write Prompts" section
import useGroupMembership from './useGroupMembership'; // Custom hook to check if the user is an admin

// Define categories with associated colors for templates
const CATEGORIES = [
  { name: "BI", color: "#FF0000" },  // Business Intelligence (Red)
  { name: "IT", color: "#800080" },  // Information Technology (Purple)
  { name: "HR", color: "#008000" }   // Human Resources (Green)
];

// Constants for the maximum length of template titles and descriptions
const TITLE_MAX_LENGTH = 30;
const DESCRIPTION_PREVIEW_LENGTH = 250;

function Templates() {
    const { accounts, instance } = useMsal(); // MSAL hook for authentication
    const isAuthenticated = accounts.length > 0; // Boolean to check if the user is authenticated
    const isAdmin = useGroupMembership(); // Check if the user is an admin

    // Extract the user's first and last name from MSAL account details
    const firstName = accounts[0]?.idTokenClaims?.given_name || '';
    const lastName = accounts[0]?.idTokenClaims?.family_name || '';
    const fullName = `${firstName} ${lastName}`.trim() || accounts[0]?.username; // If no names, fallback to username

    const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State to track sidebar visibility
    const [templates, setTemplates] = useState([]); // State to hold all templates
    const [searchTerm, setSearchTerm] = useState(''); // State to track the search term
    const [selectedCategory, setSelectedCategory] = useState('All'); // State for filtering templates by category
    const [viewingTemplate, setViewingTemplate] = useState(null); // State to track if a specific template is being viewed
    const [isFormOpen, setIsFormOpen] = useState(false); // State to show/hide form modal
    const [formType, setFormType] = useState(''); // State to track whether the form is for creating or editing a template
    const [formTemplate, setFormTemplate] = useState({ id: '', title: '', description: '', category: '' }); // State for form data
    const [formTitle, setFormTitle] = useState(''); // State for the form modal title
    const [copyButtonText, setCopyButtonText] = useState('Copy Template'); // State for the copy button text
    const [variableValues, setVariableValues] = useState({}); // State to track values for dynamic variables in templates

    // Function to handle user logout using MSAL
    const handleLogout = () => {
        instance.logoutRedirect();
    };

    // Dummy useEffect to load some predefined templates when the component loads
    useEffect(() => {
        setTemplates([
            { id: '1', title: 'Revenue Report', description: 'What was the total revenue for [Market] in the last quarter, and what are the year-over-year changes?', category: 'BI' },
            { id: '2', title: 'Customer Satisfaction', description: 'Analyze the customer satisfaction scores for [Market] over the past year.', category: 'IT' },
            { id: '3', title: 'Employee Onboarding', description: 'Create an onboarding process for new hires in the [Department] department.', category: 'HR' }
        ]);
    }, []);

    // Function to extract variables (inside [brackets]) from a template description
    const extractVariables = (description) => {
        const regex = /\[(.*?)\]/g;
        let matches;
        const variables = [];
        while ((matches = regex.exec(description)) !== null) {
            variables.push(matches[1]);
        }
        return variables;
    };

    // Function to replace variables in the template with user-input values
    const fillTemplateWithVariables = (description, variables) => {
        let filledDescription = description;
        Object.keys(variables).forEach(variable => {
            filledDescription = filledDescription.replace(`[${variable}]`, variables[variable] || `[${variable}]`);
        });
        return filledDescription;
    };

    // Handle viewing a specific template
    const handleViewTemplate = (template) => {
        setViewingTemplate(template);
        const variables = extractVariables(template.description);
        setVariableValues(variables.reduce((acc, variable) => ({ ...acc, [variable]: '' }), {})); // Initialize variable values
    };

    // Handle going back to the template list after viewing a template
    const handleBackToTemplates = () => {
        setViewingTemplate(null);
    };

    // Handle copying the template content with filled variables to clipboard
    const handleCopyTemplate = () => {
        const filledDescription = fillTemplateWithVariables(viewingTemplate.description, variableValues);
        navigator.clipboard.writeText(filledDescription).then(() => {
            setCopyButtonText('Copied'); // Temporarily show "Copied" on the button
            setTimeout(() => setCopyButtonText('Copy Template'), 2000); // Reset the button text after 2 seconds
        }, () => {
            setCopyButtonText('Copy Template'); // Fallback in case of an error
        });
    };

    // Open the form for creating or editing a template
    const openForm = (type, template = { id: '', title: '', description: '', category: '' }) => {
        setFormType(type);
        setFormTitle(type === 'submit' ? 'Create Template' : 'Edit Template');
        setFormTemplate({ ...template });
        setIsFormOpen(true); // Show the modal form
    };

    // Close the form modal
    const closeForm = () => {
        setIsFormOpen(false);
    };

    // Handle input changes in the form
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "title" && value.length > TITLE_MAX_LENGTH) return; // Restrict the title length
        setFormTemplate(prevState => ({ ...prevState, [name]: value }));
    };

    // Handle input changes for dynamic variables
    const handleVariableInputChange = (variable, value) => {
        setVariableValues(prevState => ({ ...prevState, [variable]: value }));
    };

    // Submit the form to either create or update a template
    const handleSubmit = (e) => {
        e.preventDefault();
        if (formType === 'submit') {
            setTemplates([...templates, { ...formTemplate, id: (templates.length + 1).toString() }]); // Add new template
        } else {
            setTemplates(templates.map(template => template.id === formTemplate.id ? formTemplate : template)); // Edit existing template
        }
        setIsFormOpen(false); // Close the modal form
        setFormTemplate({ id: '', title: '', description: '', category: '' }); // Reset form state
    };

    // Handle deleting a template
    const handleDelete = (id) => {
        setTemplates(templates.filter(template => template.id !== id)); // Remove the deleted template from state
    };

    // Filter templates based on the search term and selected category
    const filteredTemplates = templates.filter(template => {
        const searchTermLower = searchTerm.toLowerCase();
        const templateTitle = typeof template.title === 'string' ? template.title.toLowerCase() : '';
        const matchesSearchTerm = templateTitle.includes(searchTermLower) || 
                                template.category.toLowerCase().includes(searchTermLower) || 
                                template.id.toLowerCase().includes(searchTermLower) ||
                                template.description.toLowerCase().includes(searchTermLower);
        const matchesCategory = selectedCategory === "All" || template.category === selectedCategory;
        return (!searchTerm || matchesSearchTerm) && matchesCategory;
    });

    // Utility function to truncate text if it exceeds the specified length
    const truncateText = (text, length) => {
        if (typeof text !== 'string') {
            return '';
        }
        return text.length <= length ? text : `${text.substring(0, length)}...`;
    };

    // Function to toggle the sidebar open or closed
    const toggleMenu = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <>
            {isAuthenticated ? ( // If the user is authenticated, render the page
                <>
                    <Header toggleMenu={toggleMenu} isAuthenticated={isAuthenticated} username={fullName} handleLogout={handleLogout} />
                    <Sidebar isOpen={isSidebarOpen} toggleMenu={toggleMenu} />
                    <div className="templates">
                        {/* Show intro section if not viewing a template */}
                        {!viewingTemplate && (
                            <div className="intro-container">
                                <div className="intro-box">
                                    <h1>Welcome to the Slalom Prompt Library</h1>
                                    <p>
                                        The Slalom Prompt Library is a centralized hub designed to enhance your productivity and creativity with AI-powered prompts. Whether you're working in BI, IT, or HR, you’ll find prompts that cater to your specific needs, helping you to streamline tasks, generate new ideas, and collaborate more effectively.
                                    </p>
                                </div>
                            </div>
                        )}

                        {/* If viewing a specific template */}
                        {viewingTemplate ? (
                            <div className="view-template-container">
                                <h1>{viewingTemplate.title}</h1>
                                <div className="template-category" style={{ backgroundColor: CATEGORIES.find(cat => cat.name === viewingTemplate.category)?.color || '#ffffff' }}>{viewingTemplate.category}</div>
                                <div className="template-variables">
                                    <p className="template-guidance">Enter values for the variables below to customize your template. Press "Enter" after typing in a value to see the updated prompt.</p>
                                    {Object.keys(variableValues).map(variable => (
                                        <div key={variable} className="variable-input">
                                            <label>{variable}</label>
                                            <input
                                                type="text"
                                                value={variableValues[variable]}
                                                onChange={(e) => handleVariableInputChange(variable, e.target.value)}
                                                onKeyDown={(e) => handleVariableInputChange(variable, e.target.value)}
                                            />
                                        </div>
                                    ))}
                                </div>
                                <p className="view-template-description">
                                    {fillTemplateWithVariables(viewingTemplate.description, variableValues)}
                                </p>
                                <div className="view-template-buttons">
                                    <button onClick={handleCopyTemplate} className="view-template-copy-button">{copyButtonText}</button>
                                    <button onClick={handleBackToTemplates} className="view-template-back-button">Back to Templates</button>
                                </div>
                            </div>
                        ) : ( // If not viewing a template, show list of templates
                            <>
                                {/* Category filter buttons */}
                                <div className="category-filters">
                                    <button
                                        className={`category-filter ${selectedCategory === 'All' ? 'active' : ''}`}
                                        onClick={() => setSelectedCategory('All')}
                                    >
                                        Show All
                                    </button>
                                    {CATEGORIES.map(cat => (
                                        <button
                                            key={cat.name}
                                            className={`category-filter ${selectedCategory === cat.name ? 'active' : ''}`}
                                            onClick={() => setSelectedCategory(cat.name)}
                                        >
                                            {cat.name}
                                        </button>
                                    ))}
                                </div>

                                {/* Search bar */}
                                <div className="search-section">
                                    <div className="search-bar">
                                        <img src="/search.svg" alt="Search" className="search-icon" />
                                        <input type="text" placeholder="Search" onChange={event => setSearchTerm(event.target.value)} />
                                    </div>
                                </div>

                                {/* Display the list of templates */}
                                <section className="ai-template-library">
                                    <div className="popular-templates">
                                        {filteredTemplates.map((template) => {
                                            const categoryColor = CATEGORIES.find(cat => cat.name === template.category)?.color || '#ffffff';
                                            return (
                                                <div className="template" key={template.id}>
                                                    <div className="template-category" style={{ backgroundColor: categoryColor }}>{template.category}</div>
                                                    <h3>{truncateText(template.title, TITLE_MAX_LENGTH)}</h3>
                                                    <p className="template-description">{truncateText(template.description, DESCRIPTION_PREVIEW_LENGTH)}</p>
                                                    <div className="template-buttons">
                                                        <button onClick={() => handleViewTemplate(template)} className="view-template-button">View Template</button>
                                                        {isAuthenticated && isAdmin && (
                                                            <>
                                                                <button onClick={() => openForm('edit', template)} className="edit-button">Edit</button>
                                                                <button onClick={() => handleDelete(template.id)} className="delete-button">Delete</button>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {/* Button to create a new template */}
                                    {isAuthenticated && (
                                        <button className="submit-template-button" onClick={() => openForm('submit')}>+ Create Template</button>
                                    )}
                                </section>
                                <HowToWritePrompts />
                                <FAQ />
                            </>
                        )}
                        {/* Modal for create/edit form */}
                        {isFormOpen && (
                            <Modal isOpen={isFormOpen} onClose={closeForm}>
                                <h2>{formTitle}</h2>
                                <form onSubmit={handleSubmit} className="prompt-form">
                                    <label>
                                        Title:
                                        <input type="text" name="title" value={formTemplate.title} onChange={handleInputChange} maxLength={TITLE_MAX_LENGTH} required />
                                    </label>
                                    <label>
                                        Description:
                                        <textarea name="description" value={formTemplate.description} onChange={handleInputChange} required />
                                    </label>
                                    <label>
                                        Category:
                                        <select name="category" value={formTemplate.category} onChange={handleInputChange} required>
                                            <option value="">Select Category</option>
                                            {CATEGORIES.map(category => (
                                                <option key={category.name} value={category.name}>{category.name}</option>
                                            ))}
                                        </select>
                                    </label>
                                    <button type="submit" className="form-submit-button">{formType === 'submit' ? 'Create Template' : 'Update Template'}</button>
                                </form>
                            </Modal>
                        )}
                    </div>
                </>
            ) : (
                <p>You must be signed in to view this page.</p>
            )}
        </>
    );
}

export default Templates;
