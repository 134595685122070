// src/App.js
import React, { useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import Home from './Home';
import Templates from './Templates';
import Sidebar from './Sidebar';
import Header from './Header';
import './App.css';

function App() {
  const { accounts, instance } = useMsal();
  const [isMenuOpen, setIsMenuOpen] = useState(true); // Default to open
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const isAuthenticated = useIsAuthenticated();
  const username = isAuthenticated && accounts.length > 0 ? accounts[0].name : '';

  const handleLogout = () => {
    instance.logoutRedirect().then(() => {
      navigate('/login'); // Redirect to login after logout
    });
  };

  return (
    <div className="App">
      {/* Make sure Header and Sidebar are outside of Routes so they render for all pages */}
      <Header
        isAuthenticated={isAuthenticated}
        username={username}
        handleLogout={handleLogout}
        toggleMenu={toggleMenu} // Pass toggleMenu to Header
      />
      <Sidebar isOpen={isMenuOpen} toggleMenu={toggleMenu} />

      <div className={`main-content ${isMenuOpen ? 'shifted' : ''}`}>
        <Routes>
          <Route
            path="/"
            element={
              <Home
                isAuthenticated={isAuthenticated}
                username={username}
                toggleMenu={toggleMenu}
                handleLogout={handleLogout}
                isMenuOpen={isMenuOpen}
              />
            }
          />
          <Route
            path="/templates"
            element={
              <Templates
                isAuthenticated={isAuthenticated}
                username={username}
                toggleMenu={toggleMenu}
                handleLogout={handleLogout}
                isMenuOpen={isMenuOpen}
              />
            }
          />
        </Routes>
      </div>
    </div>
  );
}

export default App;
