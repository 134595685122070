import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { PublicClientApplication, EventType, InteractionRequiredAuthError } from '@azure/msal-browser';
import { MsalProvider, useIsAuthenticated } from '@azure/msal-react';
import App from './App';
import Login from './Login';
import { msalConfig } from './authConfig';
import reportWebVitals from './reportWebVitals';
import ProtectedRoute from './ProtectedRoute';
import Home from './Home';
import Templates from './Templates';

// Initialize MSAL instance
const msalInstance = new PublicClientApplication(msalConfig);

// Ensure MSAL instance is properly initialized before making any API calls
await msalInstance.initialize();

const renderApp = () => {
  const container = document.getElementById('root');
  const root = createRoot(container);

  function AppRoutes() {
    const isAuthenticated = useIsAuthenticated();

    return (
      <Routes>
        <Route path="/" element={<Navigate to={isAuthenticated ? "/app" : "/login"} />} />
        <Route path="/login" element={<Login />} />
        <Route path="/app" element={<ProtectedRoute element={<App />} />} />
        <Route path="/home" element={<Home />} />
        <Route path="/templates" element={<Templates />} />
      </Routes>
    );
  }

  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <MsalProvider instance={msalInstance}>
          <AppRoutes />
        </MsalProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
};

const initMsal = async () => {
  try {
    const response = await msalInstance.handleRedirectPromise();

    if (response !== null) {
      msalInstance.setActiveAccount(response.account);
    } else {
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
      } else {
        console.log("No accounts detected, redirecting to login...");
        msalInstance.loginRedirect();
      }
    }

    msalInstance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const authResult = event.payload;
        msalInstance.setActiveAccount(authResult.account);
      }
    });

    renderApp();
  } catch (error) {
    console.error('Error handling redirect promise or silent token acquisition:', error);

    if (error instanceof InteractionRequiredAuthError) {
      console.log("Redirecting to login due to interaction required error...");
      msalInstance.loginRedirect();
    } else {
      renderApp();
    }
  }
};

initMsal();

reportWebVitals();
